<template>
<v-container>
  <v-row v-if="this.mode != 'Popup' && this.mode != 'DongTai'">
    <v-flex class="progress_bottom_down pa-1" style="cursor: pointer;" @click="displayIntercomListMore">
      <span class="progress_bottom_down_btn" v-html="'全部沟通 ' + this.intercom.length" />
      <v-icon v-if="intercom.length > 3" class="progress_bottom_down_btn triple_down_icon" :class="this.triple_down ? 'triple_down_icon_rotation' : ''">mdi-chevron-double-down</v-icon>
    </v-flex>
  </v-row>
  <v-row class="pa-1 " v-if="this.mode != 'Popup' && this.mode != 'DongTai'">
    <!-- {{this.triple_down}}--{{this.intercom.length}} -->
    <!--解析评论的列表内容-->
    <IntercomItem 
      v-if="intercom.length != 0" 
      v-for="ic in this.triple_down? intercom : intercom.slice(intercom.length - 3)" 
      :key="ic.ic_id" 
      :icObj="ic" 
      :auth_id="!!authUser ? authUser.id : null" 
      @handleReplyBtnClick="_handleReplyBtnClick" 
      @deleteCertainIntercom="handle_deleteCertainIntercom" 
      @updateIntercom="" 
    />
  
  </v-row>
  <v-row class="pa-3" v-if="!!this.ic_reply_ic">
    <v-flex shrink class="progress_bottom_down_btn" style="margin-right: 20px;">回复</v-flex>
    <v-flex class="progress_bottom_down_btn" @click="_cancelReply">
      {{ !!this.ic_reply_ic && this.ic_reply_ic.ic_sender_name }}
      <v-icon class="hide_btns">
        mdi-close-circle-outline
      </v-icon>
    </v-flex>
  </v-row>
  <v-row v-if="!this.goal">
    <v-textarea ref="intercomDescription" :rows="4" placeholder="你在忙什么" background-color="white" filled auto-grow outlined v-model="ic_desc" @input="" @blur="onBlur" @focus="onFocus">
    </v-textarea>
  </v-row>
  <v-row v-if="
                !this.goal &&
                    !(this.mode == 'DongTai' && !this.chkFocusBlur && !ic_desc)
            " class="d-flex justify-start ma-0 pa-0">
    <div class="d-flex pa-2 justify-center intercom-btn" @click="_handleFileInputClick">
      <span>
        <v-icon class="btn_2-icons">mdi-paperclip</v-icon>
        <span>附件</span>
      </span>
      <input type="file" ref="fileIntercom" style="display:none;" @change="setFileForIntercom" />
    </div>
    <div class="d-flex pa-2 justify-center intercom-btn">
      <v-menu top origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <span class="ma-2">@ 同事</span>
          </span>
        </template>
        <v-card>
          <UserPicker @pick="pickUser" />
        </v-card>
      </v-menu>
    </div>

    <div class="d-flex pa-2 justify-center intercom-btn" @click="_handleAllMemberClick">
      <span>
        <span>@ 所有同事</span>
      </span>
    </div>

    <div class=" d-flex justify-center intercom-btn">
      <EmojiPicker @inputEmoji="_handleInputEmojiClick" />
    </div>

    <div class="d-flex pa-0 justify-center intercom-btn">
      <v-checkbox class="sms-check ma-0" v-model="ic_sms" label="短信通知" hide-details></v-checkbox>
    </div>

    <div class="d-flex pl-3 justify-center">
      <v-btn color="primary" :disabled="
                        DescriptionCheck.length > 0 || intercomFiles.length > 0
                            ? false
                            : true
                    " @click="_handleICSubmitClick">{{ this.mode == "DongTai" ? "发布" : "回复" }}</v-btn>
    </div>
  </v-row>
  <v-row>
    <v-container v-if="intercomFiles.length > 0" class="m_0">
      <v-row v-for="(file, index) in intercomFiles" :key="index" class="pa-2 file-item">
        <v-flex shrink>
          <v-icon color="rgb(73, 185, 221)" class="mr-2">
            mdi-file-upload
          </v-icon>
        </v-flex>

        <v-flex d-flex align-center justify-start grow>
          <span style="color:rgb(100, 116, 129);">
            {{ file.file_name.split(".")[0] }}
          </span>
        </v-flex>

        <v-flex shrink>
          <span style="color:rgb(45, 171, 146);margin-right:10px;font-size:12px;">
            文件上传成功
          </span>
        </v-flex>

        <v-flex shrink class="file_remove">
          <v-icon size="15" color="rgb(169, 184, 191)" style="cursor:pointer;" class="file_remove_close" @click="removeFile(file.file_id)">
            mdi-close-circle
          </v-icon>
        </v-flex>
      </v-row>
    </v-container>
  </v-row>
</v-container>
</template>

<script>
import EmojiPicker from "@/components/common/EmojiPicker.vue";
import UserPicker from "@/components/common/UserPicker.vue";
import IntercomItem from "./IntercomItem";
import {
  mapGetters,
  mapActions
} from "vuex";
export default {
  props: ["ic_obj", "goal", "mode"],
  components: {
    IntercomItem,
    UserPicker,
    EmojiPicker,
  },
  data() {
    return {
      intercom: [],
      ic_desc: "",
      ic_reply_ic: null,
      ic_sms: false,
      triple_down: false,
      chkFocusBlur: false,
      isNum:true,
    };
  },
  computed: {
    // ...mapGetters("intercom", ["intercom"]),
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("fileUpload", ["intercomFiles"]),
    DescriptionCheck() {
      return this.ic_desc.replaceAll(/@\[.*\]/g, "");
    },
  },
  watch: {
    ic_obj(value) {
      this.getIntercom(value).then((res) => {
        this.intercom = res;
      });
    },
  },
  created() {
    this.getIntercom(this.ic_obj).then((res) => {
      this.intercom = res;
    });
  },
  methods: {
    ...mapActions("intercom", [
      "getIntercom",
      "addIntercom",
      "deleteIntercom",
      "updateIntercom",
    ]),
    ...mapActions("fileUpload", [
      "initFiles",
      "fileUpload",
      "deleteFileUploadById",
    ]),
    onBlur(e) {
      this.chkFocusBlur = false;
    },
    onFocus(e) {
      this.chkFocusBlur = true;
    },
    displayIntercomListMore() {
      this.triple_down = !this.triple_down;
      if (this.intercom.length < 4) {
        this.triple_down = true;
      }
    },
    _handleFileInputClick() {
      this.$refs.fileIntercom.click();
    },

    pickUser(param) {
      this.ic_desc += "@[" + param.user.user_post_address + "]";
    },

    _handleAllMemberClick() {
      this.ic_desc += "@[所有同事:all_members]";
    },

    _handleInputEmojiClick(e) {
      this.ic_desc += e;
      this.$refs.intercomDescription.focus();
    },

    setFileForIntercom(e) {
      this.file = e.target.files[0];
      var formData = new FormData();
      formData.append("files", this.file);
      this.fileUpload({
        type: "intercom",
        value: formData,
      });
    },
    removeFile(id) {
      this.deleteFileUploadById({
        type: "intercom",
        file_id: id,
      });
    },

    _handleReplyBtnClick(prop) {
      this.ic_reply_ic = prop;
      this.$refs.intercomDescription.focus();
    },
    _cancelReply() {
      this.ic_reply_ic = null;
    },
    handle_deleteCertainIntercom(prop) {
      let that = this;
      this.deleteIntercom({
        ic_id: prop,
      }).then((res) => {
        if (res) {
          certainIndex = that.intercom.findIndex(
            (e) => e.ic_id == prop
          );
          if (certainIndex != -1) {
            that.intercom.splice(certainIndex, 1);
          }
        }
      });
    },

    _handleICSubmitClick() {
      let files = [];
      this.intercomFiles.forEach((f) => {
        files.push(f.file_id);
      });
      this.initFiles({
        type: "intercom",
      });

      let ic_add = {
        ...this.ic_obj,
      };
      ic_add.ic_description = this.ic_desc;
      ic_add.ic_reply_ic = !!this.ic_reply_ic ?
        this.ic_reply_ic.ic_id :
        null;
      ic_add.ic_thumbup = null;
      ic_add.ic_uploaded_file = files.toString();

      this.addIntercom(ic_add).then((res) => {
        if (!!res) {
          this.intercom.push(res);

          this.$emit("submit", ic_add);
          this.ic_desc = "";
          this.ic_reply_ic = null;
           
        }
      });
      
      
      
    },
  },
};
</script>

<style scoped>
.btn_2-icons {
  font-size: 17px !important;
  margin-right: 5px;
}

.intercom-btn {
  background-color: white;
  cursor: pointer;
  padding: 1px 1px;
  height: 35px;
  width: 90px;
  font-size: 13px;
  color: rgb(86, 94, 102);
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
}

.intercom-btn:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 2px;
}

.file_remove_close:hover {
  color: red !important;
}

.file_remove {
  transition: all ease 0.5s;
  opacity: 0;
}

.file-item:hover .file_remove {
  opacity: 1;
}

</style><style>.sms-check label {
  color: rgb(86, 94, 102) !important;
  font-size: 13px !important;
  padding: 1px 1px;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
}

.sms-check .v-icon {
  font-size: 15px !important;
  line-height: 20px;
}

.progress_bottom_down_btn {
  font-size: 13px !important;
  color: rgb(40, 121, 255);
}

.progress_bottom_down:hover .progress_bottom_down_btn {
  color: blue !important;
}

.progress_bottom_down_btn:hover {
  color: red !important;
}
</style>
