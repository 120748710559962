<template>
    <v-container class="pa-1">
        <!-- <v-hover
            v-slot="{ hover }"
        > -->
            <v-card flat class="pa-2" >
                <v-container >
                    <v-row justify-space-between>
                        <!-- <v-avatar color="rgb(121, 199, 183)" size="26" style = "color: white; font-size: 12px;">{{ this.icObj.ic_sender_name.charAt(0) }}</v-avatar> -->
						<UserAvatar :fullName="this.icObj.ic_sender_name" :name="this.icObj.ic_sender_name.charAt(0)" width='26px' height='26px' :userId="this.icObj.ic_sender" />
                        <span class="pl-1 pr-2">{{ this.icObj.ic_sender_name }}</span>
                        <span v-if = "this.icObj.ic_replys.length != 0" class = "reply pr-2" style = "font-weight: 70 !important;">回复</span>
                        <span v-if = "this.icObj.ic_replys.length != 0" class = "pr-2">{{ this.icObj.ic_replys[0].ic_sender_name }}</span>
                        <ToolTipComponent
                            v-if = "this.icObj.ic_replys.length != 0"
                            direction="top"
                            :tooltipText="this.icObj.ic_replys[0].ic_description"
                        >
                            <span v-if = "this.icObj.ic_replys.length != 0" class = "reply">的话 : </span> 
                        </ToolTipComponent>
                    </v-row>
                    <v-row class="pl-4">
                        <span style="display: inline-block; white-space: pre-wrap;"> {{ this.icObj.ic_description }} </span>
                    </v-row>
                    <v-row class="pl-4" v-if="this.icObj.ic_files.length != 0">
                        <v-layout d-flex align-center justify-start column class="pl-1">
                            <v-flex d-flex align-center justify-start class="width100"  v-for="(file, index) in this.icObj.ic_files" :key="index">
                                    <v-icon v-if="file != ''" color='rgb(73, 185, 221)' style="margin-right: 10px;">mdi-file-upload</v-icon>
                                    <a :href="fileDownloadEndPoint + '/' + file.file_id" download>{{ file.file_name.split(".")[0] }}</a>
                            </v-flex>
                        </v-layout>
                    </v-row>
                    
                    <v-row class="pl-4 pt-3" justify-space-between>
                        <v-col cols="10">
                            <v-row>
                                <v-flex shrink style = "margin-right: 10px;">{{ parseInt(month) }}月{{ parseInt(day) }}日</v-flex>
                                <v-flex> {{ hr }}:{{ min }}</v-flex>
                            </v-row>
                        </v-col>
                        <v-col >
                            <v-row>
                                <v-flex  shrink class="pr-2"  style="cursor:pointer;" @click="_handleReplyBtnClick" >回复</v-flex>
                                <v-flex v-if = "auth_id!=this.icObj.ic_sender && (this.icObj.ic_thumbup == 0 || !this.icObj.ic_thumbup)" shrink class="editBtns" @click="displayOkBtn">点赞</v-flex>
                                <v-flex v-if = "auth_id!=this.icObj.ic_sender && this.icObj.ic_thumbup == 1" shrink class="editBtns" @click="displayAsBtn">已赞 1</v-flex>
                                <ConfirmDialogComponent v-if="auth_id===this.icObj.ic_sender" v-on:click="bool => confirmClick(bool)" :option="intercom"> 
                                    <v-flex shrink class="editBtns">删除</v-flex>
                                </ConfirmDialogComponent>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        <!-- </v-hover> -->
    </v-container>
</template>

<script>
import ConfirmDialogComponent from '@/components/okrgoal/shared/ConfirmDialogComponent.vue';
import ToolTipComponent from '@/components/okrgoal/shared/ToolTipComponent.vue';
import * as endPoint from "@/config/endpoints";
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['icObj', 'auth_id'],
    components: {
        ConfirmDialogComponent,
        ToolTipComponent,
				UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    },
    data() {
       return {
           ok_btn: false,
           as_btn: true,
           year: null,
           month: null,
           day: null,
           hr: null,
           min: null,
           intercom: "intercom",
       }
    },
    // watch:{
    //     icObj(val, oldVal){
    
    //     }
    // },
    mounted(){
        
    },
    created() {
        
        const time = new Date(this.icObj.created_at);
        this.year = time.getFullYear();
        this.month = time.getMonth() + 1;
        if(this.month < 10)
        {
            this.month = "0" + this.month.toString();
        }
        this.day = time.getDate();
        if(this.day < 10)
        {
            this.day = "0" + this.day.toString();
        }
        this.hr = time.getHours();
        if(this.hr < 10)
        {
            this.hr = "0" + this.hr.toString();
        }
        this.min = time.getMinutes();
        if(this.min < 10)
        {
            this.min = "0" + this.min.toString();
        }
    },
    computed: {
        ...mapGetters('intercom', ['parentIntercom']),
        fileDownloadEndPoint() {
            return endPoint.FILE_DOWNLOAD;
        }
    },
    methods: {
        ...mapActions('fileUpload', ['fileDownload']),
        ...mapActions('intercom', ['getIntercomOne']),
        _handleReplyBtnClick() {
            this.$emit("handleReplyBtnClick", this.icObj);
        },
        confirmClick(prop) {
            if(prop == true)
            {
               this.$emit("deleteCertainIntercom", this.icObj.ic_id);
            }
        },
        displayOkBtn() {
            this.ok_btn = true;
            this.as_btn = false;
            let param = {
                ic_id:  this.icObj.ic_id,
                ic_thumbup: 1
            }
            this.$emit("updateIntercom", param);
        },
        displayAsBtn() {
            this.as_btn = true;
            this.ok_btn = false;
            let param = {
                ic_id:  this.icObj.ic_id,
                ic_thumbup: 0
            }
            this.$emit("updateIntercom", param);
        },
        fileDownloadByID(id) {
            this.fileDownload(id);
        },
        async getIntercom(id) {
            const res = await this.getIntercomOne({"ic_id" : id});
            return res;
        }
    }
}
</script>
<style scoped>

.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
 }

 .show-btns {
  color: rgba(0, 0, 0, 1) !important;
}

</style>


<style>
.w100 {
    width: 100%;
}
.reply {
    font-size: 14px;
    font-weight: bold;
    margin-right: 3px;
}
.mr_3 {
    margin-right: 3px;
}
.mb_40 {
    margin-bottom: 35px;
}
.background {
    background: rgb(250, 251, 252);
}
.multi-line {
    white-space: pre-line;
}
</style>